import React from 'react'
import styled from 'styled-components'

import { useSiteQuery } from '../hooks'

import {
  mobileVw,
  desktopBreakpoint,
  desktopVw,
  colors,
  fonts,
} from '../styles'
import { Image } from '../components/shared/Image'
import { BreadCrumbs } from '../components/shared'

const Success = () => {
  const { accountDecoration } = useSiteQuery()

  return (
    <>
      <Wrapper>
        <StyledBreadCrumbs
          breadcrumbs={[{ buttonText: 'home', target: { slug: '/' } }]}
          color='#000'
          title='Success'
        />
        <StyledTitle>Merci</StyledTitle>
        <p>
          Des offres et des informations exclusives sur nos produits arrivent
          bientôt sur votre boîte de réception.
        </p>
      </Wrapper>
      <ImageWrapper>
        <StyledImage fluid={accountDecoration[0].image.fluid} />
        <StyledDesktopImage fluid={accountDecoration[1].image.fluid} />
      </ImageWrapper>
    </>
  )
}
const Wrapper = styled.div`
  margin: ${mobileVw(80)} 0 0;
  padding: ${mobileVw(33)} ${mobileVw(16)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(44)} 0 0;
    padding: 0 ${desktopVw(126)};
  }
`

const StyledBreadCrumbs = styled(BreadCrumbs)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    margin: 0 0 ${desktopVw(20)};
  }
`
const StyledTitle = styled.h1`
  color: ${colors.mailleGold};
  font-family: ${fonts.splandor};
`

const ImageWrapper = styled.div`
  margin: ${mobileVw(-75)} 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: flex-end;
    margin: ${desktopVw(17)} 0 0;
  }
`

const StyledImage = styled(Image)`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const StyledDesktopImage = styled(Image)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    width: ${desktopVw(570)};
  }
`

export default Success
